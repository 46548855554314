<template>
  <v-dialog v-model="dialog" max-width="600">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" color="primary" text>
        {{ $t("dashboard.selectDashboard") }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title>{{ $t("dashboard.navigationTitle") }}</v-card-title>
      <v-card-text>
        <DashboardCategoryViewer v-if="dialog" v-on:close="dialog = false" />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="primary lighten-1" text @click="dialog = false">{{
          $t("common.close")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DashboardCategoryViewer from "./DashboardCategoryViewer.vue";
export default {
  name: "DashboardTreeSelect",
  data() {
    return {
      dialog: false,
    };
  },

  components: {
    DashboardCategoryViewer,
  },
};
</script>