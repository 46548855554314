import Repository from "../Repository";
import { getAuthHeader } from "../../_helpers/apiHelper";

const resource = "/applications";

export default {
  create(user, companyId, applicationPayload) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.post(
      `${resource}/${companyId}`,
      applicationPayload,
      config
    );
  },

  update(user, id, applicationPayload) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.put(`${resource}/${id}`, applicationPayload, config);
  },

  delete(user, applicationId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.delete(`${resource}/${applicationId}`, config);
  },

  get(user, applicationId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(`${resource}/${applicationId}`, config);
  },
};
