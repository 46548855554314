export default {
  getMetricsUrlParams(metrics) {
    var params = [];

    for (var k in metrics) {
      if (Array.isArray(metrics[k])) {
        params.push(`${k}=${metrics[k].map((v) => encodeURIComponent(v)).join(",")}`);
      } else {
        params.push(`${k}=${encodeURIComponent(metrics[k])}`);
      }
    }

    return "?" + params.join("&");
  }
};
