import Repository from "../Repository";
import { getAuthHeader } from "../../_helpers/apiHelper";

const resource = "/gateway";

export default {
  create(user, applicationId, gatewayPayload) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.post(
      `${resource}/${applicationId}`,
      gatewayPayload,
      config
    );
  },

  delete(user, gatewayId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.delete(`${resource}/${gatewayId}`, config);
  },

  getByCompany(user, companyId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(`${resource}/company/${companyId}`, config);
  },

  getById(user, gatewayId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(`${resource}/${gatewayId}`, config);
  },

  updatePosition(user, gatewayId, position) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.put(`${resource}/${gatewayId}/pos`, position, config);
  },
};
