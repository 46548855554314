import Repository from "../Repository";
import { apiConfig, company } from "../../_helpers/apiHelper";

const resource = "/features";
const debug = false;

export default {
  getAllFeatures() {
    // SuperAdmin Endpoint
    if (debug)
      return [
        {
          featureAccessId: 1,
          name: "FEATURE_MAP_DASHBOARD",
          //          companies: [],
          companies: [{ name: "IotAB", companyId: 1 }],
        },
      ];

    return Repository.get(`${resource}`, apiConfig());
  },

  toggleFeature(featureAccessId, companyId) {
    return Repository.put(
      `${resource}/${featureAccessId}/${companyId}`,
      null,
      apiConfig()
    );
  },

  getCompanyFeatures() {
    return Repository.get(`${resource}/${company()}`, apiConfig());
  },
};
