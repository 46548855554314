import RoleRepository from "../../api/repositories/roleRepository.js";
import restrictHelper from "../../_helpers/RestrictHelper.js";

export const roles = {
  namespaced: true,
  state: {
    role: {},
    roles: [],
    recommended: [],
    permissions: [],
  },

  actions: {
    async getRolesByCompany({ commit, dispatch, rootState }) {
      await RoleRepository.getAllByCompany(
        rootState.users.currentUser,
        rootState.users.userCompany.companyId
      )
        .then((d) => {
          commit("SET_ROLES", d.data);
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }
        });
    },

    getRoleById({ state, commit }, id) {
      var res = state.roles.find((e) => e.userRoleId == id);
      if (res != null) commit("SET_ROLE", res);
    },

    async createRole({ dispatch, rootState }, payload) {
      await RoleRepository.create(
        rootState.users.currentUser,
        rootState.users.userCompany.companyId,
        payload
      ).then(() => dispatch("getRolesByCompany"));
    },

    async updateRole({ dispatch, rootState }, payload) {
      await RoleRepository.update(
        rootState.users.currentUser,
        payload.userRoleId,
        payload
      ).then(() => dispatch("getRolesByCompany"));
    },

    async getRecommendedPermissions({ commit, rootState }, ids) {
      if (ids == null || ids.length <= 0) {
        commit("SET_RECOMMENDED", []);
        return;
      }

      await RoleRepository.getRecommendedPermissions(
        rootState.users.currentUser,
        ids
      ).then((d) => {
        commit("SET_RECOMMENDED", d.data);
      });
    },

    async getPermissions({ commit, rootState }) {
      await RoleRepository.getPermissions(rootState.users.currentUser).then(
        (d) => {
          commit("SET_PERMISSIONS", d.data);
        }
      );
    },

    setRole({ state, commit }, userRoleId) {
      var role = state.roles.find((e) => e.userRoleId == userRoleId);

      if (role != null) commit("SET_ROLE", role);
    },
  },

  mutations: {
    SET_ROLE(state, role) {
      state.role = role;
    },

    SET_ROLES(state, roles) {
      state.roles = roles;
    },

    SET_RECOMMENDED(state, value) {
      state.recommended = value;
    },

    SET_PERMISSIONS(state, value) {
      state.permissions = value;
    },
  },

  getters: {
    defaultsWithPermissions(status) {
      return status.roles.filter((d) => d.isDefault);
    },

    permittedRoles(state) {
      if (!restrictHelper.methods.permitted("UserRole.Update")) return [];

      if (restrictHelper.methods.permitted("SuperAdmin")) return state.roles;

      return state.roles.filter((e) => !e.isSuper);
    },
  },
};
