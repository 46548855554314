import { RepositoryFactory } from "../../api/RepositoryFactory";
import i18n from "@/plugins/i18n";
const Gateway = RepositoryFactory.get("gateways");

export const gateways = {
  namespaced: true,
  state: {
    currentGateway: {},
    gateways: [],
    status: {
      loading: false,
      loggedIn: false,
    },
  },

  actions: {
    async createGateway(
      { dispatch, commit, rootState },
      { applicationId, gatewayPayload }
    ) {
      commit("LOADING", true);

      var user = rootState.users.currentUser;

      await Gateway.create(user, applicationId, gatewayPayload)
        .then((gateway) => {
          dispatch("getGateways");
          commit("CREATE_GATEWAY", gateway);
          commit("LOADING", false);
          dispatch("alert/success", i18n.t("common.created"), { root: true });
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), { root: true });
          commit("LOADING", false);
        });
    },

    async deleteGateway({ dispatch, commit, rootState }, gatewayId) {
      commit("LOADING", true);

      var user = rootState.users.currentUser;

      await Gateway.delete(user, gatewayId)
        .then(() => {
          dispatch("alert/success", i18n.t("common.deleted"), { root: true });
          dispatch("getGateways");
          commit("LOADING", false);
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), { root: true });
          commit("LOADING", false);
        });
    },

    async getGateway({ dispatch, commit, rootState }, id) {
      commit("LOADING", true);

      var user = rootState.users.currentUser;

      await Gateway.getById(user, id)
        .then((res) => {
          commit("SET_GATEWAY", res.data);
          commit("LOADING", false);
        })
        .catch((res) => {
          if (res.response !== undefined && res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          commit("LOADING", false);
        });
    },

    async getGateways({ dispatch, commit, rootState }) {
      var user = rootState.users.currentUser;
      var company = rootState.users.userCompany.companyId;
      commit("LOADING", true);
      await Gateway.getByCompany(user, company)
        .then((data) => {
          commit("SET_GATEWAYS", data.data);
          commit("LOADING", true);
        })
        .catch((res) => {
          if (res.response !== undefined && res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          commit("LOADING", false);
        });
    },

    async updatePosition(
      { dispatch, commit, rootState },
      { gatewayId, position }
    ) {
      var user = rootState.users.currentUser;

      commit("LOADING", true);
      await Gateway.updatePosition(user, gatewayId, position)
        .then(() => {
          dispatch("getGateways");
          commit("LOADING", true);
        })
        .catch(() => {
          commit("LOADING", false);
        });
    },
  },

  mutations: {
    LOADING(state, status) {
      state.status.loading = status;
    },

    CREATE_GATEWAY(state, gateway) {
      state.currentGateway = gateway;
    },

    SET_GATEWAY(state, gateway) {
      state.currentGateway = gateway;
    },

    SET_GATEWAYS(state, gateways) {
      state.gateways = gateways;
    },
  },
};
