import Vue from "vue";
import Vuetify from "vuetify/lib";
import "vuetify/dist/vuetify.min.css";
import "@mdi/font/css/materialdesignicons.css";
import { colors } from "vuetify/lib";

Vue.use(Vuetify);

const opts = {
  iconfont: "mdi",
  theme: {
    dark: false,
    themes: {
      light: {
        primary: "#2196F3",
        accent: colors.blue.accent1,
        success: colors.green,
        info: colors.yellow.accent3,
        error: colors.red.accent1,
        background: colors.grey.lighten5,
        altBg: colors.grey.lighten3,
      },
      dark: {
        primary: colors.blue.darken3, //"#5b4fd6",
        secondary: colors.blue.darken1,
        accent: colors.lightBlue.accent4,
        success: colors.green,
        info: colors.yellow.accent3,
        error: colors.red.accent1,
        background: colors.grey.darken3,
        altBg: colors.grey.darken1,
      },
    },
  },
};

export default new Vuetify(opts);
