export const Roles = {
  Admin: "Admin",
  User: "User",
  Guest: "Guest",
  CompanyAdmin: "CompanyAdmin",
  SuperAdmin: "SuperAdmin",
  PublicDashboard: "PublicDashboard",

  AdminOrUser: ["Admin", "CompanyAdmin", "SuperAdmin"],
  CompanyOrUser: ["Admin", "CompanyAdmin", "User", "SuperAdmin"],
  AdminOrCompanyAdmin: ["Admin", "CompanyAdmin", "SuperAdmin"],
};
