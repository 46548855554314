import { mapActions, mapState } from "vuex";

export default {
  computed: {
    ...mapState("settings", ["moduleSettings"]),
  },
  methods: {
    ...mapActions("settings", ["addModuleSetting"]),

    storeTimeOffset(moduleId, timeOffset) {
      this.addModuleSetting({ moduleId, timeOffset });
    },

    getModuleSettingsTimeOffset(moduleId) {
      if (moduleId != undefined || moduleId != null)
        return this.moduleSettings[moduleId] != undefined ||
          this.moduleSettings[moduleId] != null
          ? this.moduleSettings[moduleId]
          : undefined;

      return undefined;
    },
  },
};
