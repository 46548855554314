import Repository from "../Repository";
import { getAuthHeader } from "../../_helpers/apiHelper";

const resource = "/users";

export default {
  get(user) {
    var config = {
      headers: getAuthHeader(user)
    };

    return Repository.get(`${resource}`, config);
  },

  getAll(user) {
    var config = {
      headers: getAuthHeader(user)
    };

    return Repository.get(`${resource}/all`, config);
  },

  getById(user, userId) {
    var config = {
      headers: getAuthHeader(user)
    };

    return Repository.get(`${resource}/${userId}`, config);
  },

  getCompany(user) {
    var config = {
      headers: getAuthHeader(user)
    };

    return Repository.get(`${resource}/${user.userId}/company`, config);
  },

  register(payload) {
    return Repository.post(`${resource}`, payload);
  },

  edit(user, userId, payload) {
    var config = {
      headers: getAuthHeader(user)
    };

    return Repository.put(`${resource}/${userId}`, payload, config);
  },

  delete(user, userId) {
    var config = {
      headers: getAuthHeader(user)
    };

    return Repository.delete(`${resource}/${userId}`, config);
  },

  updateRole(user, targetUserId, role) {
    var config = {
      headers: getAuthHeader(user)
    };

    return Repository.put(
      `${resource}/${targetUserId}/role/${role}`,
      null,
      config
    );
  },

  login(email, password) {
    const data = {
      email: email,
      password: password
    };

    return Repository.post(`${resource}/authenticate`, data);
  },

  createUserWithCompany(user, payload) {
    var config = {
      headers: getAuthHeader(user)
    }
    return Repository.post(`${resource}/create/company`, payload, config);
  },

  forgotPassword(email) {
    return Repository.post(`${resource}/forgot/${email}`, null);
  },

  forgotPasswordUpdate(guid, password) {
    return Repository.put(`${resource}/forgot/${guid}`, { password: password });
  }
};
