import ModbusRepository from "../../api/repositories/modbusRepository";

export const modbus = {
  namespaced: true,
  state: {
    masters: [],
  },

  actions: {
    async getMasters({ commit }) {
      await ModbusRepository.getMasters()
        .then((d) => commit("SET_MASTERS", d.data))
        .catch(() => commit("SET_MASTERS", []));
    },
  },

  mutations: {
    SET_MASTERS(state, payload) {
      state.masters = payload;
    },
  },

  getters: {
    getMasterNameById: (state) => (id) => {
      return state.masters.find((e) => e.modbusMasterId == id)?.name ?? id;
    },
  },
};
