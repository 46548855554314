<template>
  <v-snackbar
    style="z-index: 99999999"
    :timeout="30000"
    :value="showLicenseNotification && isInValidPath"
    @input="(v) => setLicenseNotification(v)"
    absolute
    center
    rounded
    top
    color="warning"
  >
    <span v-if="$route.path.includes('gateways/create')">{{
      $t("license.limitations.errors.gateway")
    }}</span>
    <span v-else-if="$route.path.includes('tag/create')">{{
      $t("license.limitations.errors.tag")
    }}</span>
    <span v-else-if="$route.path.includes('company/create')">{{
      $t("license.limitations.errors.company")
    }}</span>
    <span v-else>{{
      $t("license.limitations.errors.expiresIn", [expiresIn])
    }}</span>

    <template v-slot:action="{ attrs }">
      <v-btn icon v-bind="attrs" @click="() => setLicenseNotification(false)">
        <v-icon>mdi-alpha-x</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "LicenseNotification",

  data() {
    return {};
  },

  computed: {
    ...mapState("configuration", [
      "showLicenseNotification",
      "canCreateGateway",
      "canCreateNode",
      "canCreateCompany",
      "showLicenseExpiery",
      "expiresIn",
    ]),

    isInValidPath() {
      if (
        (this.$route.path.includes("gateways/create") &&
          !this.canCreateGateway) ||
        (this.$route.path.includes("tag/create") && !this.canCreateNode) ||
        ((this.$route.path.includes("company/create") ||
          this.$route.path.includes("register")) &&
          !this.canCreateCompany)
      )
        return true;

      if (this.showLicenseExpiery) return true;

      return false;
    },
  },

  methods: {
    ...mapActions("configuration", [
      "getCanCreateBools",
      "setLicenseNotification",
    ]),
  },

  async created() {
    await this.getCanCreateBools();
    this.setLicenseNotification(true);
  },
};
</script>