import Repository from "../Repository";
import { apiConfig, getAuthHeader } from "../../_helpers/apiHelper";
import metricHelper from "../../_helpers/metricHelper";

const resource = "/tag";
const tagDataResource = "/tagdata";

export default {
  get(user) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(`${resource}/tagdata`, config);
  },

  getKeys(user, company) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(`${resource}/tagdata/keys/${company}`, config);
  },

  getTrendDataForTag(tagId, tagKey, metrics) {
    return Repository.get(
      `${resource}/${tagId}/trend/${tagKey}${metrics}`,
      apiConfig()
    );
  },

  getCurrentTagdata(tagId, tagKey) {
    return Repository.get(`${resource}/latest/${tagId}/${tagKey}`, apiConfig());
  },

  getCurrentForTags(tagIds, tagKey) {
    let queries = {
      deveuis: tagIds,
      key: tagKey,
    };

    return Repository.get(
      `${tagDataResource}/currents${metricHelper.getMetricsUrlParams(queries)}`,
      apiConfig()
    );
  },

  getCurrentsMultiKey(tagId, tagKeys) {
    return Repository.get(
      `${tagDataResource}/currents/${tagId}${metricHelper.getMetricsUrlParams({
        keys: tagKeys,
      })}`,
      apiConfig()
    );
  },

  getCurrentsForDevice(user, deveui) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(`${resource}/${deveui}/currents`, config);
  },

  // tagsWithKeys expected structure:
  // [
  //   {deveui: "example-deveui", key: "example-key"},
  //   {deveui: "example-deveui-2", key: "example-key-2"},
  // ]
  //
  // We expect the from and to dates to be URI encoded already
  getTimeSeriesTagData(tagsWithKeys, from, to) {
    return Repository.post(
      `${tagDataResource}/timeseries/${from}/${to}`,
      tagsWithKeys,
      apiConfig()
    );
  },

  getTimeSeriesTagDataSingle(deveui, key, from, to) {
    return Repository.get(
      `${tagDataResource}/timeseries/${deveui}/${key}/${from}/${to}`,
      apiConfig()
    );
  },

  getCurrentsMultiTagAndKey(deveuisAndKeys) {
    return Repository.post(
      `${tagDataResource}/currents`,
      deveuisAndKeys,
      apiConfig()
    );
  },

  getTagdataWithLimit(deveuisAndKeys, limit) {
    return Repository.post(
      `${tagDataResource}/limit/${limit}`,
      deveuisAndKeys,
      apiConfig()
    );
  },
};
