import Repository from "../Repository";
import { getAuthHeader } from "../../_helpers/apiHelper";

const resource = "/integration";
const tokenResource = "/integration/tokens";

export default {
  getAccessTokens(user, companyId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(`${tokenResource}/${companyId}`, config);
  },

  deleteToken(user, tokenId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.delete(`${tokenResource}/${tokenId}`, config);
  },

  createToken(user, companyId, payload) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.post(`${tokenResource}/${companyId}`, payload, config);
  },

  seedNordpool(user, year, month, day) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.post(
      `${resource}/nordpool/${year}/${month}/${day}`,
      null,
      config
    );
  },
};
