import TemplateDashboardRepository from "../../api/repositories/templateDashboard";

export const templateDashboard = {
  namespaced: true,

  state: {
    dashboard: {},
    widgets: {},
    dashboards: [],
  },

  actions: {
    saveDashboardToState({ commit }, dashboard) {
      commit("SET_DASHBOARD", dashboard);
    },

    removeWidgetFromState({ commit }, widgetId) {
      commit("REMOVE_WIDGET", widgetId);
    },

    reset({ commit }) {
      commit("RESET_DASHBOARD");
    },

    async getTemplateDashboard({ commit }, templateDashboardId) {
      await TemplateDashboardRepository.getDashboard(templateDashboardId)
        .then((d) => commit("SET_DASHBOARD", d.data))
        .catch((e) => console.error(e));
    },

    async getTemplateDashboards({ commit }, commitToStore = true) {
      let result = await TemplateDashboardRepository.getDashboards()
        .then((d) => d.data)
        .catch((e) => {
          console.error(e);
        });
      if (result !== undefined && commitToStore)
        commit("SET_TEMPLATE_DASHBOARDS", result);

      return result;
    },

    async getDashboardWidgets({ commit }, templateDashboardId) {
      let result = await TemplateDashboardRepository.getWidgets(
        templateDashboardId
      )
        .then((d) => d.data)
        .catch((e) => {
          console.error(e);
        });

      commit("SET_WIDGETS", result ?? []);
      return result ?? [];
    },

    setWidgets({ commit }, widgets) {
      commit("SET_WIDGETS", widgets);
    },

    addOrUpdateWidget({ commit }, widget) {
      commit("ADD_OR_UPDATE_WIDGET", widget);
    },

    // Should return the templateDashboardId
    async createTemplateDashboard(_, payload) {
      var result = await TemplateDashboardRepository.create(payload)
        .then((d) => [true, d.data])
        .catch((d) => [false, d]);

      if (!result[0]) {
        console.error(result[1]);
        return undefined;
      }

      // result[1] should contain a string of the guid for Template dashboard ID
      return result[1];
    },

    async updateTemplateDashboard(_, { templateDashboardId, payload }) {
      return await TemplateDashboardRepository.update(
        templateDashboardId,
        payload
      )
        .then(() => true)
        .catch(() => false);
    },

    async saveTemplateDashboardWidgets({ getters }, payload) {
      // Get the current template dashboard id
      let tId = getters.templateDashboardId;
      if (tId === undefined) {
        console.error("No template dashboard ID was found in store");
        return;
      }

      return await TemplateDashboardRepository.updateWidgets(tId, payload)
        .then(() => [true, "Ok!"])
        .catch((d) => [false, d]);
    },

    async deleteTemplateDashboard(_, templateDashboardId) {
      return await TemplateDashboardRepository.delete(templateDashboardId)
        .then(() => true)
        .catch((d) => {
          console.error(d);
          return false;
        });
    },
  },

  mutations: {
    REMOVE_WIDGET(state, id) {
      let widgets = state.widgets;
      if (widgets === undefined) return;

      let idx = widgets.findIndex((d) => d.templateWidgetId === id);
      if (idx !== -1) widgets.splice(idx, 1);

      // Index widgets
      let i = 0;
      for (var w of state.widgets) {
        w.i = i++;
      }
    },

    SET_DASHBOARD(state, dashboard) {
      state.dashboard = dashboard;
    },

    RESET_DASHBOARD(state) {
      state.dashboard = {};
      state.widgets = [];
    },

    SET_TEMPLATE_DASHBOARDS(state, val) {
      state.dashboards = val;
    },

    SET_WIDGETS(state, val) {
      state.widgets = val;
    },

    ADD_OR_UPDATE_WIDGET(state, val) {
      if (state.widgets?.length === undefined) state.widgets = [];

      let widgetIdx = state.widgets.findIndex(
        (d) => d.templateWidgetId === val.templateWidgetId
      );
      if (widgetIdx !== -1) {
        // Update the widget instead
        state.widgets[widgetIdx] = val;
        return;
      }

      state.widgets.push(val);

      // Index widgets
      let i = 0;
      for (var w of state.widgets) {
        w.i = i++;
      }
    },
  },

  getters: {
    templateDashboardId(state) {
      return state.dashboard?.templateDashboardId ?? undefined;
    },
  },
};
