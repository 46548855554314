// This store is used only by frontend to keep track of slots between
// components instead of sending full objects all around
export const slots = {
  namespaced: true,

  state: {
    slots: [],
  },

  actions: {
    addSlot({ commit }, { slotId, index, key }) {
      commit("ADD_SLOT", { slotId, index, key });
    },

    resetSlots({ commit }) {
      commit("RESET_SLOTS");
    },
  },

  mutations: {
    ADD_SLOT(state, { slotId, index, key }) {
      for (var slot of state.slots) {
        if (slotId === slot.slotId) return;

        if (slot.key == key && slot.index == index) return;
      }

      state.slots.push({
        slotId: slotId === undefined ? crypto.randomUUID() : slotId,
        index: index,
        slot: "Node Slot " + index,
        key: key,
        priority: state.slots?.length + 1 ?? 0,
      });
    },

    ADD_KEY_TO_SLOT(state, index, key) {
      var slotIdx = state.slots.findIndex((d) => d.index == index);
      if (slotIdx == -1) return;

      state.slots[slotIdx].key = key;
    },

    RESET_SLOTS(state) {
      state.slots = [];
    },
  },

  getters: {
    getSlot: (state) => (index, key) => {
      var slot = state.slots.findIndex((d) => d.index == index && d.key == key);
      if (slot === -1) return null;

      return state.slots[slot];
    },

    getSlotNameById: (state) => (id) => {
      var slot = state.slots.findIndex((d) => d.slotId === id);
      if (slot === -1) return "";

      return (
        "Node Slot " + state.slots[slot].index + " - " + state.slots[slot].key
      );
    },

    getSlotName: (state) => (index, key) => {
      var slot = state.slots.findIndex(
        (d) => d.index === index && d.key === key
      );
      if (slot === -1) return "";

      return (
        "Node Slot " + state.slots[slot].index + " - " + state.slots[slot].key
      );
    },
  },
};
