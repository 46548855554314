import FeatureAccessRepository from "@/api/repositories/featureAccessRepository";

export const featureAccess = {
  namespaced: true,

  state: {
    companyFeatures: [],
    features: [],
  },

  actions: {
    async getAllFeatures({ commit }) {
      let values = await FeatureAccessRepository.getAllFeatures()
        .then((d) => d.data)
        .catch(() => undefined);
      if (values === undefined)
        values = values
          .then((d) => d.data)
          .catch(() => {
            console.error("Could not fetch features");
            return [];
          });

      commit("SET_FEATURES", values);
    },

    async getCompanyFeatures({ commit }) {
      let result = await FeatureAccessRepository.getCompanyFeatures()
        .then((d) => d.data)
        .catch(() => {
          console.error("Could not fetch company features");
          return undefined;
        });
      if (result === undefined) {
        commit("SET_COMPANY_FEATURES", [{ name: "FEATURE_MAP_DASHBOARD" }]);
        return;
      }

      commit("SET_COMPANY_FEATURES", result);
    },
  },

  mutations: {
    SET_FEATURES(state, features) {
      state.features = features;
    },

    SET_COMPANY_FEATURES(state, features) {
      state.companyFeatures = features;
    },
  },

  getters: {
    companyFeatures(state) {
      return state.companyFeatures;
    },
  },
};
