import { gmapApi } from "vue2-google-maps";

export default {
  methods: {
    getAddress(latLng) {
      return new Promise((resolve, reject) => {
        const geocoder = new this.mixinGoogleMaps.maps.Geocoder();
        geocoder.geocode({ location: latLng }, (results, status) => {
          if (status == "OK") {
            resolve(results[0].formatted_address);
          } else {
            reject(status);
          }
        });
      });
    },

    async reverseGeoCode(latLng) {
      if (!latLng) return;
      return await this.getAddress(latLng);
    },
  },

  computed: {
    mixinGoogleMaps: gmapApi,
  },
};
