import { RepositoryFactory } from "../../api/RepositoryFactory";
const DownlinkRepository = RepositoryFactory.get("downlinks");

export const downlinks = {
  namespaced: true,
  state: {
    downlinks: [],
    status: {
      loading: false,
    },
  },

  actions: {
    async getForTag({ dispatch, commit, rootState }, deveui) {
      if (!rootState.users.status.loggedIn) return;

      commit("LOADING", true);

      var user = rootState.users.currentUser;

      return await DownlinkRepository.getForTag(user, deveui)
        .then((downlinks) => {
          commit("GET_DOWNLINKS", downlinks.data);
          commit("LOADING", false);
          return downlinks.data;
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          commit("LOADING", false);
          return [];
        });
    },

    async removeDownlink({ dispatch, commit, rootState }, downlinkQueueId) {
      if (!rootState.users.status.loggedIn) return;
      commit("LOADING", true);
      var user = rootState.users.currentUser;

      await DownlinkRepository.removeDownlink(user, downlinkQueueId)
        .then(() => commit("LOADING", false))
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          commit("LOADING", false);
        });
    },

    async createDownlink({ commit, dispatch, rootState }, { deveui, payload }) {
      if (!rootState.users.status.loggedIn) return;
      commit("LOADING", true);
      var user = rootState.users.currentUser;

      await DownlinkRepository.createDownlink(user, deveui, payload)
        .then(() => commit("LOADING", false))
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          commit("LOADING", false);

        });
    }
  },

  mutations: {
    LOADING(state, status) {
      state.status.loading = status;
    },

    GET_DOWNLINKS(state, downlinks) {
      state.downlinks = downlinks;
    },
  },
};
