import Repository from "../Repository";
import { getAuthHeader } from "../../_helpers/apiHelper";

const resource = "/camonline";

export default {
  get(user, companyId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(`${resource}/${companyId}`, config);
  },

  create(user, companyId, payload) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.post(`${resource}/${companyId}`, payload, config);
  },

  delete(user, camOnlineServerId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.delete(`${resource}/${camOnlineServerId}`, config);
  },
};
