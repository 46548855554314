import axios from "axios";
import CORepository from "../../api/repositories/camOnlineRepository";
import i18n from "../../plugins/i18n";

export const camOnline = {
  namespaced: true,
  state: {
    servers: [],
  },

  actions: {
    async getServers({ dispatch, commit, rootState }) {
      var user = rootState.users.currentUser;
      var company = rootState.users.userCompany.companyId;

      await CORepository.get(user, company)
        .then((d) => commit("SET_SERVERS", d.data))
        .catch(() =>
          dispatch("alert/error", i18n.t("camOnline.couldNotFetchServers"), {
            root: true,
          })
        );
    },

    async createServer({ dispatch, rootState }, payload) {
      var user = rootState.users.currentUser;
      var company = rootState.users.userCompany.companyId;
      await CORepository.create(user, company, payload)
        .then(async () => await dispatch("getServers"))
        .catch(() =>
          dispatch("alert/error", i18n.t("camOnline.couldNotCreateServer"), {
            root: true,
          })
        );
    },

    async deleteServer({ dispatch, rootState }, serverId) {
      var user = rootState.users.currentUser;

      await CORepository.delete(user, serverId)
        .then(async () => await dispatch("getServers"))
        .catch(() =>
          dispatch("alert/error", i18n.t("camOnline.couldNotDeleteServer"), {
            root: true,
          })
        );
    },

    async getStreams({ state }, serverId) {
      var server = state.servers.find((e) => e.camOnlineServerId == serverId);
      if (server == undefined) {
        console.error("Could not find the requested server: " + serverId);
        return [];
      }

      try {
        var result = await axios.get(server.url + "/api/stream/all", {
          headers: {
            Authorization: `Bearer ${server.accessToken}`,
          },
        });

        return result.data;
      } catch {
        console.error("Something went wrong while fetching streams");
        return [];
      }
    },
  },

  mutations: {
    SET_SERVERS(state, servers) {
      state.servers = servers;
    },
  },

  getters: {
    getServer: (state) => (id) => {
      var server = state.servers.find((e) => e.camOnlineServerId == id);
      return server ?? {};
    },
  },
};
