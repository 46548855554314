import Vue from "vue";
import Vuex from "vuex";
import { accessTokens } from "./modules/accessToken";
import { alarms } from "./modules/alarm";
import { alert } from "./modules/alert";
import { applications } from "./modules/application";
import { camOnline } from "./modules/camOnline";
import { companies } from "./modules/company";
import { configuration } from "./modules/configuration";
import { dashboards } from "./modules/dashboard";
import { downlinks } from "./modules/downlinks";
import { event } from "./modules/event";
import { featureAccess } from "./modules/featureAccess";
import { gateways } from "./modules/gateway";
import { languages } from "./modules/language";
import { logs } from "./modules/log";
import { modbus } from "./modules/modbus";
import { modules } from "./modules/modules";
import { mqtt } from "./modules/mqtt";
import { notifications } from "./modules/notification";
import { opc } from "./modules/opc";
import { reports } from "./modules/report";
import { roles } from "./modules/roles";
import { scripts } from "./modules/scripts";
import { settings } from "./modules/settings";
import { signalTests } from "./modules/signalTest";
import { slots } from "./modules/slots";
import { tag } from "./modules/tag";
import { tagData } from "./modules/tagdata";
import { templateDashboard } from "./modules/templateDashboard";
import { users } from "./modules/user";

import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    accessTokens: accessTokens,
    alarms: alarms,
    alert: alert,
    applications: applications,
    camOnline: camOnline,
    companies: companies,
    configuration: configuration,
    dashboards: dashboards,
    downlinks: downlinks,
    event: event,
    featureAccess: featureAccess,
    gateways: gateways,
    languages: languages,
    logs: logs,
    modbus: modbus,
    modules: modules,
    mqtt: mqtt,
    notifications: notifications,
    opc: opc,
    reports: reports,
    roles: roles,
    scripts: scripts,
    settings: settings,
    signalTests: signalTests,
    slots: slots,
    tag: tag,
    tagData: tagData,
    templateDashboard: templateDashboard,
    users: users,
  },
  plugins: [createPersistedState()],
});
