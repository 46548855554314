import { RepositoryFactory } from "../../api/RepositoryFactory";

const IntegrationRepository = RepositoryFactory.get("integrations");

export const accessTokens = {
  namespaced: true,
  state: {
  },

  actions: {
    async getAccessTokens({rootState}) {
      var user = rootState.users.currentUser

      return await IntegrationRepository.getAccessTokens(user, rootState.users.userCompany.companyId)
        .then(d => d.data);
    },

    async deleteAccessToken({rootState}, tokenId) {
        var user = rootState.users.currentUser
        await IntegrationRepository.deleteToken(user, tokenId);
    },

    async createToken({rootState}, payload) {
        await IntegrationRepository.createToken(rootState.users.currentUser, rootState.users.userCompany.companyId, payload);
    }

    
  },

  mutations: {
  },
};
