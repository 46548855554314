export default {
  computed: {
    soTheme() {
      return this.$vuetify.theme.currentTheme;
    },

    isDark() {
      return this.$vuetify.theme.dark;
    },

    defaultTextColor() {
      return this.$vuetify.theme.dark ? "#ffffff" : "#0a0a0a";
    },
  },
};
