export default {
  computed: {
    isInsideDashboard() {
      let reg = this.$route.path.match(
        /^\/dashboard\/((?:[^\\/]+?))(?:\/(?=$))?$/i
      );

      return reg != null && reg.length > 0;
    }
  },
};
