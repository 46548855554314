import MqttRepository from "../../api/repositories/mqttRepository.js";
import i18n from "../../plugins/i18n";

export const mqtt = {
  namespaced: true,
  state: {
    brokers: [],
    brokersWithMetrics: [],
    mqttMeta: {},
  },

  actions: {
    async getBrokers({ commit, rootState, dispatch }) {
      var user = rootState.users.currentUser;
      var company = rootState.users.userCompany.companyId;

      await MqttRepository.getBrokers(user, company)
        .then((d) => {
          commit("SET_BROKERS", d.data);
        })
        .catch(() => {
          dispatch("alert/error", i18n.t("common.error"), { root: true });
        });
    },

    async getBrokersWithMetrics({ commit, rootState, dispatch }) {
      var user = rootState.users.currentUser;
      var company = rootState.users.userCompany.companyId;

      await MqttRepository.getBrokersWithMetrics(user, company)
        .then((d) => {
          commit("SET_BROKERS_WITH_METRICS", d.data);
        })
        .catch(() => {
          dispatch("alert/error", i18n.t("common.error"), { root: true });
        });
    },

    async createBroker({ rootState, dispatch }, payload) {
      var user = rootState.users.currentUser;
      var company = rootState.users.userCompany.companyId;

      let result = false;
      await MqttRepository.createBroker(user, company, payload)
        .then(() => {
          dispatch("alert/success", i18n.t("common.created"), { root: true });
          result = true;
        })
        .catch(() => {
          dispatch("alert/error", i18n.t("common.error"), { root: true });
        });

      return result;
    },

    async updateBroker({ rootState, dispatch }, { brokerId, payload }) {
      var user = rootState.users.currentUser;

      let result = false;
      await MqttRepository.updateBroker(user, brokerId, payload)
        .then(() => {
          dispatch("alert/success", i18n.t("common.updated"), { root: true });
          result = true;
        })
        .catch(() => {
          dispatch("alert/error", i18n.t("common.error"), { root: true });
        });

      return result;
    },

    async deleteBroker({ rootState, dispatch }, brokerId) {
      var user = rootState.users.currentUser;

      let result = false;
      await MqttRepository.deleteBroker(user, brokerId)
        .then(() => {
          dispatch("alert/success", i18n.t("common.deleted"), { root: true });
          result = true;
        })
        .catch(() => {
          dispatch("alert/error", i18n.t("common.error"), { root: true });
        });

      return result;
    },

    async createMqttMeta(
      { dispatch, rootState },
      { brokerId, deveui, payload }
    ) {
      var user = rootState.users.currentUser;

      let result = false;
      await MqttRepository.createMqttMeta(user, brokerId, deveui, payload)
        .then(() => {
          result = true;
        })
        .catch(() => {
          dispatch("alert/error", i18n.t("common.error"), { root: true });
        });

      return result;
    },

    async updateMqttMeta(
      { dispatch, rootState },
      { mqttMetaId, brokerId, payload }
    ) {
      var user = rootState.users.currentUser;

      let result = false;
      await MqttRepository.updateMqttMeta(user, mqttMetaId, brokerId, payload)
        .then(() => {
          result = true;
          dispatch("alert/success", i18n.t("common.updated"), { root: true });
        })
        .catch(() => {
          dispatch("alert/error", i18n.t("common.error"), { root: true });
        });

      return result;
    },
  },

  mutations: {
    SET_BROKERS(state, data) {
      state.brokers = data;
    },

    SET_BROKERS_WITH_METRICS(state, data) {
      state.brokersWithMetrics = data;
    },
  },

  getters: {
    brokers(state) {
      return state.brokers;
    },

    brokersWithMetrics(state) {
      return state.brokersWithMetrics;
    },
  },
};
