import Repository from "../Repository";
import { getAuthHeader } from "../../_helpers/apiHelper";

const resource = "/roles";

export default {
    getAllByCompany(user, companyId) {
        var config = {
            headers: getAuthHeader(user),
        };

        return Repository.get(`${resource}/${companyId}`, config);
    },

    getPermissions(user) {
        var config = {
            headers: getAuthHeader(user),
        };

        return Repository.get(`${resource}/permissions`, config);
    },


    getRecommendedPermissions(user, payload) {
        var config = {
            headers: getAuthHeader(user),
        };

        return Repository.post(`${resource}/recommended-permissions`, payload, config);
    },

    getUserPermissionsByUserId(user) {
        var config = {
            headers: getAuthHeader(user)
        }

        return Repository.get(`${resource}/current-user-permissions`, config);
    },

    create(user, companyId, payload) {
        var config = {
            headers: getAuthHeader(user),
        };

        return Repository.post(`${resource}/${companyId}`, payload, config);
    },


    update(user, userRoleId, payload) {
        var config = {
            headers: getAuthHeader(user),
        };

        return Repository.put(`${resource}/${userRoleId}`, payload, config);
    },


    delete(user, userRoleId) {
        var config = {
            headers: getAuthHeader(user),
        };

        return Repository.delete(`${resource}/${userRoleId}`, config);
    },
};
