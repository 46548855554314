import Repository from "../Repository";
import { getAuthHeader } from "../../_helpers/apiHelper";
import store from "../../stores/store.js";

const resource = "/mqtt";

export default {
  getBrokers(user, companyId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(`${resource}/company/${companyId}`, config);
  },

  getBrokersWithMetrics(user, companyId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(`${resource}/metrics/company/${companyId}`, config);
  },

  getBroker(user, brokerId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(`${resource}/${brokerId}`, config);
  },

  createBroker(user, companyId, payload) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.post(`${resource}/${companyId}`, payload, config);
  },

  updateBroker(user, brokerId, payload) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.put(`${resource}/${brokerId}`, payload, config);
  },

  deleteBroker(user, brokerId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.delete(`${resource}/${brokerId}`, config);
  },

  getMqttMeta(user, mqttMetaId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(`${resource}/mqttmeta/${mqttMetaId}`, config);
  },

  createMqttMeta(user, brokerId, deveui, payload) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.post(
      `${resource}/mqttmeta/${brokerId}/${deveui}`,
      payload,
      config
    );
  },

  updateMqttMeta(user, mqttMetaId, brokerId, payload) {
    var config = {
      headers: getAuthHeader(user),
    };

    var url = `${resource}/mqttmeta/${brokerId}/null`;
    if (mqttMetaId) url = `${resource}/mqttmeta/${brokerId}/${mqttMetaId}`;
    return Repository.put(url, payload, config);
  },

  deleteTopic(user, topicId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.delete(`${resource}/mqttmeta/topic/${topicId}`, config);
  },

  testBroker(user, payload) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.post(`${resource}/mqttbroker/test`, payload, config);
  },

  testDecode(payload) {
    let user = store.state?.users?.currentUser;
    if (!user) return undefined;

    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.post(`${resource}/topic/decode/test`, payload, config)
      .then((d) => {
        return { success: true, response: d.data };
      })
      .catch((d) => {
        return { success: false, response: d.response?.data ?? "Unkown error" };
      });
  },
};
