import Repository from "../Repository";
import { company, apiConfig } from "../../_helpers/apiHelper";

const resource = "/templateDashboard";

export default {
  getDashboard(templateDashboardId) {
    return Repository.get(`${resource}/${templateDashboardId}`, apiConfig());
  },

  getDashboards() {
    return Repository.get(`${resource}/list/${company()}`, apiConfig());
  },

  getWidgets(templateDashboardId) {
    return Repository.get(
      `${resource}/widgets/${templateDashboardId}`,
      apiConfig()
    );
  },

  create(payload) {
    return Repository.post(`${resource}/${company()}`, payload, apiConfig());
  },

  update(templateDashboardId, payload) {
    return Repository.put(
      `${resource}/${templateDashboardId}`,
      payload,
      apiConfig()
    );
  },

  delete(templateDashboardId) {
    return Repository.delete(`${resource}/${templateDashboardId}`, apiConfig());
  },

  updateWidgets(templateDashboardId, payload) {
    return Repository.put(
      `${resource}/widgets/${templateDashboardId}`,
      payload,
      apiConfig()
    );
  },

  removeWidget(widgetId) {
    return Repository.delete(`${resource}/widget/${widgetId}`, apiConfig());
  },

  getTemplateDashboards() {
    return Repository.get(`${resource}/${company()}`, apiConfig());
  },
};
