import { RepositoryFactory } from "../../api/RepositoryFactory";
import i18n from "@/plugins/i18n";
import { saveAs } from "file-saver";
import { format } from "date-fns";

const ReportRepository = RepositoryFactory.get("report");

export const reports = {
  namespaced: true,
  state: {
    currentReport: {},
    reports: [],
    status: {
      loading: false,
    },
  },

  actions: {
    async getReports({ dispatch, commit, rootState }) {
      commit("LOADING", true);

      var user = rootState.users.currentUser;

      await ReportRepository.getReports(
        user,
        rootState.users.userCompany.companyId
      )
        .then((reports) => {
          commit("GET_REPORTS", reports.data);
          commit("LOADING", false);
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), { root: true });
          commit("LOADING", false);
        });
    },

    async getReport({ dispatch, commit, rootState }, { reportId }) {
      commit("LOADING", true);

      var user = rootState.users.currentUser;

      await ReportRepository.getReport(user, reportId)
        .then((report) => {
          commit("GET_CURRENT", report.data);
          commit("LOADING", false);
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), { root: true });
          commit("LOADING", false);
        });
    },

    async create({ dispatch, commit, rootState }, { payload }) {
      commit("LOADING", true);

      var user = rootState.users.currentUser;

      await ReportRepository.create(user, payload)
        .then(() => {
          commit("LOADING", false);
          dispatch("getReports");
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), { root: true });
          commit("LOADING", false);
        });
    },

    async update({ dispatch, commit, rootState }, { reportId, payload }) {
      commit("LOADING", true);

      var user = rootState.users.currentUser;

      await ReportRepository.update(user, reportId, payload)
        .then(() => {
          commit("LOADING", false);
          dispatch("getReports");
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), { root: true });
          commit("LOADING", false);
        });
    },

    async delete({ dispatch, commit, rootState }, { reportId }) {
      commit("LOADING", true);

      var user = rootState.users.currentUser;

      await ReportRepository.delete(user, reportId)
        .then(() => {
          commit("LOADING", false);
          dispatch("getReports");
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), { root: true });
          commit("LOADING", false);
        });
    },

    async multiExport({ dispatch, commit, rootState }, { payload }) {
      commit("LOADING", true);

      var user = rootState.users.currentUser;

      var companyId = null;
      companyId = rootState.users.userCompany.companyId;

      await ReportRepository.multiExport(user, payload, companyId)
        .then((res) => {
          var date = format(new Date(), "YYY-MM-dd");
          saveAs(new Blob([res.data]), `SensorOnline-Report-${date}.xlsx`);
        })
        .catch((res) => {
          if (res.response.status === 404) {
            dispatch("alert/error", i18n.t("report.noData"), { root: true });
          } else
            dispatch("alert/error", i18n.t("report.downloadFailed"), {
              root: true,
            });
        });

      commit("LOADING", false);
    },
  },

  mutations: {
    LOADING(state, status) {
      state.status.loading = status;
    },

    GET_REPORTS(state, reports) {
      state.reports = reports;
    },

    GET_CURRENT(state, report) {
      state.currentReport = report;
    },
  },
};
