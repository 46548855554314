import Repository from "@/api/Repository";
import { apiConfig } from "@/_helpers/apiHelper";

const resource = "/opc";

export default {
  getServers() {
    return Repository.get(`${resource}/servers`, apiConfig());
  },

  createServer(serverDto) {
    return Repository.post(`${resource}/servers/create`, serverDto, apiConfig());
  },

  getServersById(serverId) {
    return Repository.get(`${resource}/servers/${serverId}`, apiConfig());
  },

  updateServer(serverId, serverDto) {
    return Repository.put(`${resource}/servers/${serverId}`, serverDto, apiConfig());
  },

  deleteServer(serverId) {
    return Repository.delete(`${resource}/servers/${serverId}`, apiConfig());
  },

  getNodesByServerId(serverId) {
    return Repository.get(`${resource}/servers/${serverId}/nodes`, apiConfig());
  },

  getNodesByDeveui(deveui) {
    return Repository.get(`${resource}/nodes/${deveui}`, apiConfig());
  },

  createNode(deveui, nodeDto) {
    return Repository.post(`${resource}/nodes/${deveui}`, nodeDto, apiConfig());
  },

  updateNode(nodeId, nodeDto) {
    return Repository.put(`${resource}/nodes/${nodeId}`, nodeDto, apiConfig());
  },

  deleteNode(nodeId) {
    return Repository.delete(`${resource}/nodes/${nodeId}`, apiConfig());
  },

  addNodeMonitoring(serverId, nodeId) {
    return Repository.post(`${resource}/monitoring/${serverId}/${nodeId}`, apiConfig());
  }
};