import Repository from "../Repository";
import { getAuthHeader } from "../../_helpers/apiHelper";

const resource = "/dashboards";
const moduleResource = "modules";

export default {
  get(user, dashboardId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(
      `${resource}/${dashboardId}/${moduleResource}`,
      config
    );
  },

  getModule(user, moduleId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(`${resource}/modules/${moduleId}`, config);
  },

  create(user, dashboardId, payload) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.post(
      `${resource}/${dashboardId}/${moduleResource}`,
      payload,
      config
    );
  },

  update(user, moduleId, payload) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.put(
      `${resource}/${moduleResource}/${moduleId}`,
      payload,
      config
    );
  },

  updateModules(user, payload) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.put(
      `${resource}/${moduleResource}/multiple`,
      payload,
      config
    );
  },

  delete(user, moduleId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.delete(
      `${resource}/${moduleResource}/${moduleId}`,
      config
    );
  },

  postImage(user, moduleId, image) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.post(
      `${resource}/modules/${moduleId}/image`,
      image,
      config
    );
  },

  deleteImage(user, moduleId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.delete(`${resource}/modules/${moduleId}/image`, config);
  },

  getTagDataForModule(user, moduleId, metrics) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(
      `${resource}/modules/${moduleId}/tagdata${metrics}`,
      config
    );
  },

  getTagDataForModuleByLimit(user, moduleId, limit) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(
      `${resource}/modules/${moduleId}/tagdata/limit/${limit}`,
      config
    );
  },

  getLatestTagDataForModule(user, moduleId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(
      `${resource}/modules/${moduleId}/tagdata/latest`,
      config
    );
  },

  copyModule(user, moduleId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.post(
      `${resource}/modules/copy/${moduleId}`,
      null,
      config
    );
  },
};
