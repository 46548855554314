import Repository from "../Repository";
import { getAuthHeader } from "../../_helpers/apiHelper";

const resource = "/settings";

export default {
  get(user) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(`${resource}`, config);
  },

  set(user, payload) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.post(`${resource}`, payload, config);
  },

  getGlobal(user) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(`${resource}/global`, config);
  },

  setGlobal(user, payload) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.post(`${resource}/global`, payload, config);
  },

  getCompanyFields(user) {
    var config = {
      headers: getAuthHeader(user)
    }

    return Repository.get(`${resource}/company/fields`, config);
  },

  getCompany(user, companyId) {
    var config = {
      headers: getAuthHeader(user)
    }

    return Repository.get(`${resource}/company/${companyId}`, config);
  },

  setCompany(user, companyId, payload) {
    var config = {
      headers: getAuthHeader(user)
    }

    return Repository.put(`${resource}/company/${companyId}`, payload, config);
  }
};
