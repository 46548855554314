import Repository from "../Repository";
import { getAuthHeader } from "../../_helpers/apiHelper";

const resource = "/logs";

export default {
  get(user, metrics) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(`${resource}${metrics}`, config);
  },

  getActiveUsers(user) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(`${resource}/activeusers`, config);
  },

  getDatapoints(user) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(`${resource}/datapoints`, config);
  },

  getActiveAlarms(user) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(`/alarms/active/all`, config);
  },
};
