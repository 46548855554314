import { RepositoryFactory } from "../../api/RepositoryFactory";
import i18n from "@/plugins/i18n";
const Repo = RepositoryFactory.get("signalTest");

export const signalTests = {
  namespaced: true,
  state: {
    currentTest: {},
    signalTests: [],
    testData: [],
  },

  mutations: {
    SET_TEST(state, test) {
      state.currentTest = test;
    },

    SET_TEST_DATAS(state, datas) {
      state.testData = datas;
    },

    SET_TESTS(state, tests) {
      state.signalTests = tests;
    },
  },

  actions: {
    async getTests({ rootState, dispatch }) {
      var user = rootState.users.currentUser;
      var company = rootState.users.userCompany.companyId;

      let result = [];

      await Repo.getTests(user, company)
        .then((data) => {
          result = data.data;
        })
        .catch(() => {
          dispatch("alert/error", i18n.t("common.error"), {
            root: true,
          });
        });

      return result;
    },

    async peek({ rootState, dispatch }, testId) {
      var user = rootState.users.currentUser;
      let result = [];

      await Repo.peek(user, testId)
        .then((data) => {
          result = data.data;
        })
        .catch(() => {
          dispatch("alert/error", i18n.t("common.error"), {
            root: true,
          });
        });

      return result;
    },

    async getTest({ rootState, dispatch }, testId) {
      var user = rootState.users.currentUser;
      let result = [];

      await Repo.getTest(user, testId)
        .then((data) => {
          result = data.data;
        })
        .catch(() => {
          dispatch("alert/error", i18n.t("common.error"), {
            root: true,
          });
        });

      return result;
    },

    async startTest({ commit, rootState, dispatch }, { deveui, payload }) {
      var user = rootState.users.currentUser;
      var company = rootState.users.userCompany.companyId;

      await Repo.startTest(user, company, deveui, payload)
        .then((test) => {
          dispatch("getTests");
          commit("SET_TEST", test.data);
        })
        .catch(() => {
          dispatch("alert/error", i18n.t("common.error"), {
            root: true,
          });
        });
    },

    async endTest({ rootState, dispatch }, testId) {
      var user = rootState.users.currentUser;

      await Repo.endTest(user, testId)
        .then(() => {
          dispatch("getTests");
        })
        .catch(() => {
          dispatch("alert/error", i18n.t("common.error"), {
            root: true,
          });
        });
    },

    async deleteTest({ rootState, dispatch }, testId) {
      var user = rootState.users.currentUser;

      await Repo.deleteTest(user, testId)
        .then(() => {
          dispatch("getTests");
        })
        .catch(() => {
          dispatch("alert/error", i18n.t("common.error"), {
            root: true,
          });
        });
    },
  },
};
