export const settings = {
  namespaced: true,
  state: {
    moduleSettings: [],
    googleMapsZoom: 7,
    googleMapsLatLng: null,
    googleMapsViewType: "roadmap",
    imageMapZoom: -1,
  },

  actions: {
    addModuleSetting({ commit }, { moduleId, timeOffset }) {
      commit("ADD_MODULE_SETTING", { moduleId, timeOffset });
    },

    setGoogleMapsZoom({ commit }, level) {
      commit("SET_GOOGLEMAPS_ZOOM", level);
    },

    setLatnLng({ commit }, latnlng) {
      commit("SET_LAT_LNG", latnlng);
    },

    setMapType({ commit }, viewType) {
      commit("SET_VIEW_TYPE", viewType);
    },

    setImageMapZoom({ commit }, level) {
      commit("SET_IMAGE_MAP_ZOOM", level);
    },
  },

  mutations: {
    ADD_MODULE_SETTING(state, { moduleId, timeOffset }) {
      state.moduleSettings[moduleId] = timeOffset;
    },

    SET_GOOGLEMAPS_ZOOM(state, level) {
      state.googleMapsZoom = level;
    },

    SET_LAT_LNG(state, latnLng) {
      state.googleMapsLatLng = latnLng;
    },

    SET_VIEW_TYPE(state, viewType) {
      state.googleMapsViewType = viewType;
    },

    SET_IMAGE_MAP_ZOOM(state, level) {
      state.imageMapZoom = level;
    },

    RESET_STATE(state) {
      state.moduleSettings = [];
    },
  },
};
