import OpcRepository from "@/api/repositories/opcRepository";

export const opc = {
    namespaced: true,
    state: {
        servers: [],
        nodes: []
    },
    
    actions: {
        async getServers({commit}) {
            await OpcRepository.getServers()
                .then(d => commit("SET_SERVERS", d.data))
                .catch(() => commit("SET_SERVERS", []));
        },

        async getNodesByDeveui({commit}, deveui) {
            const result = await OpcRepository.getNodesByDeveui(deveui).then(d => d.data).catch(() => []);
            commit("SET_NODES", result);
        }
    },
    
    mutations: {
        SET_SERVERS(state, payload) {
            state.servers = payload;
        },

        SET_NODES(state, payload) {
            state.nodes = payload;
        }
    },
    
    getters: {
        getServerUrlById: (state) => (id) => {
            return state.servers.find(d => d.opcServerId === id);
        }
    }
};