import store from "../stores/store";

export default {
  HttpStatus: {
    ok: 200,
    created: 201,
    accepted: 202,
    no_content: 204,
    bad_request: 400,
    unauthorized: 401,
    forbidden: 403,
    not_found: 404,
    conflict: 409,
  },

  checkForConflict(response) {
    if (
      response.status === this.HttpStatus.bad_request &&
      response.data.errors != null &&
      response.data.errors.Deveui != null
    )
      return true;
    else return false;
  },
};

export function getAuthHeader(user) {
  return {
    "Access-Control-Allow-Origin": true,
    Authorization: `Bearer ${user.token}`,
    "Content-Type": "application/json",
  };
}

export function apiConfig() {
  let user = store.getters["users/user"];
  if (user?.token == undefined)
    return {
      headers: {
        "Access-Control-Allow-Origin": true,
        "Content-Type": "application/json",
      },
    };

  return {
    headers: {
      "Access-Control-Allow-Origin": true,
      Authorization: `Bearer ${user.token}`,
      "Content-Type": "application/json",
    },
  };
}

export function company() {
  return store.getters["users/companyId"];
}

export function tagsWithKeyQueryParameter(tagsWithKeys) {
  if (typeof tagsWithKeys !== "object") return "";

  return (
    "twk=" +
    encodeURIComponent(
      Buffer.from(JSON.stringify(tagsWithKeys)).toString("base64")
    )
  );
}
