import Repository from "../Repository";
import { getAuthHeader } from "../../_helpers/apiHelper";

const resource = "/signaltest";

export default {
  getTests(user, companyId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(`${resource}/${companyId}`, config);
  },

  peek(user, testId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(`${resource}/peek/${testId}`, config);
  },

  getTest(user, testId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(`${resource}/single/${testId}`, config);
  },

  startTest(user, companyId, deveui, payload) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.post(
      `${resource}/${companyId}/${deveui}`,
      payload,
      config
    );
  },

  endTest(user, testId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.put(`${resource}/end/${testId}`, null, config);
  },

  deleteTest(user, testId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.delete(`${resource}/${testId}`, config);
  },
};
