const state = {
  active: false,
  message: null,
  alertType: "success",
};

const actions = {
  success({ commit }, message) {
    commit("success", message);
  },
  error({ commit }, message) {
    commit("error", message);
  },
  clear({ commit }, message) {
    commit("success", message);
  },
};

const mutations = {
  success(state, message) {
    state.active = true;
    state.message = message;
    state.alertType = "success";
  },
  error(state, message) {
    state.active = true;
    state.message = message;
    state.alertType = "error";
  },
  clear(state) {
    state.active = false;
    state.message = null;
    state.alertType = "success";
  },
};

export const alert = {
  namespaced: true,
  state,
  actions,
  mutations,
};
