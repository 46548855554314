import Repository from "../Repository";
import { company, apiConfig, getAuthHeader } from "../../_helpers/apiHelper";

const resource = "/dashboards";
const companyResource = "/companies";

export default {
  getDashboards(user, companyId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(`${companyResource}/${companyId}/dashboards`, config);
  },

  getDashboard(user, dashboardId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(`${resource}/${dashboardId}`, config);
  },

  duplicate(user, dashboardId, payload) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.post(
      `${resource}/${dashboardId}/duplicate`,
      payload,
      config
    );
  },

  create(user, payload) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.post(`${resource}`, payload, config);
  },

  createForCompany(payload, dashboardType = undefined) {
    let url =
      `${resource}/create/${company()}` +
      (dashboardType !== undefined ? "/" + dashboardType : "");
    return Repository.post(url, payload, apiConfig());
  },

  update(user, dashboardId, payload) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.put(`${resource}/${dashboardId}`, payload, config);
  },

  delete(user, dashboardId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.delete(`${resource}/${dashboardId}`, config);
  },

  setDefault(user, dashboardId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.put(`${resource}/${dashboardId}/default`, null, config);
  },

  getDefault(user) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(`${resource}/default`, config);
  },

  createPublicDashboard(user, companyId, dashboardId, expiresAt) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.post(
      `${resource}/public/${companyId}/${dashboardId}/${expiresAt}`,
      null,
      config
    );
  },

  getPublicDashboards(user, companyId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(`${resource}/public/company/${companyId}`, config);
  },

  getPublicDashboard(publicDashboardId) {
    return Repository.get(`${resource}/public/${publicDashboardId}`);
  },

  deletePublicDashboard(user, publicDashboardId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.delete(`${resource}/public/${publicDashboardId}`, config);
  },

  getDashboardCategories(user, companyId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(`${resource}/categories/${companyId}`, config);
  },

  createDashboardCategories(user, companyId, payload) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.post(
      `${resource}/categories/${companyId}`,
      payload,
      config
    );
  },

  updateDashboardCategories(user, categoryId, payload) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.put(
      `${resource}/categories/${categoryId}`,
      payload,
      config
    );
  },

  updateDashboardIndexes(user, payload) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.put(`${resource}/categories/indexes`, payload, config);
  },

  removeDashboardIndexes(user, categoryId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.delete(`${resource}/categories/${categoryId}`, config);
  },

  removeDashboardMarker(markerId) {
    return Repository.delete(`${resource}/map/marker/${markerId}`, apiConfig());
  },

  createOrUpdateMarker(dashboardId, payload) {
    return Repository.post(
      `${resource}/map/marker/${dashboardId}`,
      payload,
      apiConfig()
    );
  },
};
