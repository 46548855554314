<template>
  <v-container
    style="max-height: 200px; overflow-y: scroll"
    class="icon-selector"
  >
    <p @click="toggleDisplay" style="width: 100%; cursor: pointer; margin: 0">
      <v-icon :color="color">{{ iconValue }}</v-icon>
      {{ $t("common.iconSelector.selectIcon") }}
    </p>
    <v-container v-if="display" style="max-width: 600px; height: 201px">
      <v-text-field
        v-model="searchTerm"
        color="white"
        dark
        :placeholder="$t('common.iconSelector.searchPlaceholder')"
        append-outer-icon="mdi-search"
      ></v-text-field>
      <v-icon
        v-for="(v, i) in icons"
        :key="i"
        :color="color"
        @click="setValue(v)"
        >{{ v }}</v-icon
      >
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: "IconSelector",
  props: {
    icon: { default: "mdi-shape-outline" },
    open: { default: false },
    color: { default: "accent" },
  },
  data() {
    return {
      searchTerm: "",
      iconValue: "mdi-shape",
      display: false,
      page: 0,
      avaliableIcons: [
        "mdi-access-point-check",
        "mdi-access-point-network",
        "mdi-access-point",
        "mdi-account-box",
        "mdi-account-cash",
        "mdi-account-check",
        "mdi-account-circle",
        "mdi-account-supervisor-circle-outline",
        "mdi-account-supervisor-circle",
        "mdi-account",
        "mdi-account",
        "mdi-air-conditioner",
        "mdi-alarm-light-off",
        "mdi-alarm-light",
        "mdi-alarm-multiple",
        "mdi-alarm-panel",
        "mdi-alert",
        "mdi-align-horizontal-center",
        "mdi-align-horizontal-left",
        "mdi-align-horizontal-right",
        "mdi-align-vertical-bottom",
        "mdi-align-vertical-center",
        "mdi-align-vertical-top",
        "mdi-all-inclusive",
        "mdi-android-messages",
        "mdi-antenna",
        "mdi-application-array",
        "mdi-application-braces",
        "mdi-application",
        "mdi-arrange-send-backward",
        "mdi-arrow-decision-outline",
        "mdi-arrow-decision",
        "mdi-arrow-down-bold-circle",
        "mdi-arrow-down-thick",
        "mdi-arrow-expand-all",
        "mdi-arrow-left-bold-circle",
        "mdi-arrow-left-thick",
        "mdi-arrow-right-bold-circle",
        "mdi-arrow-right-thick",
        "mdi-arrow-up-bold-circle",
        "mdi-arrow-up-thick",
        "mdi-coolant-temperature",
        "mdi-database-check",
        "mdi-database-edit",
        "mdi-database-export",
        "mdi-database",
        "mdi-desktop-mac-dashboard-outline",
        "mdi-desktop-mac-dashboard",
        "mdi-flash",
        "mdi-folder-marker",
        "mdi-folder",
        "mdi-home-city-outline",
        "mdi-home-city",
        "mdi-home-roof",
        "mdi-home",
        "mdi-map-marker",
        "mdi-marker-distance",
        "mdi-opacity",
        "mdi-palette-outline",
        "mdi-speedometer",
        "mdi-temperature-celsius",
        "mdi-temperature-fahrenheit",
        "mdi-temperature-kelvin",
        "mdi-vector-arrange-above",
        "mdi-vector-arrange-below",
        "mdi-vector-difference-ab",
        "mdi-vector-square",
        "mdi-vector-union",
        "mdi-view-dashboard-outline",
        "mdi-view-dashboard-variant-outline",
        "mdi-view-dashboard-variant",
        "mdi-view-dashboard",
        "mdi-weather-cloudy",
        "mdi-weather-cloudy",
        "mdi-weather-fog",
        "mdi-weather-lightning-rainy",
        "mdi-weather-lightning",
        "mdi-weather-partly-cloudy",
        "mdi-weather-partly-cloudy",
        "mdi-weather-partly-cloudy",
        "mdi-weather-pouring",
        "mdi-weather-pouring",
        "mdi-weather-rainy",
        "mdi-weather-rainy",
        "mdi-weather-rainy",
        "mdi-weather-rainy",
        "mdi-weather-snowy-rainy",
        "mdi-weather-snowy-rainy",
        "mdi-weather-snowy-rainy",
        "mdi-weather-snowy-rainy",
        "mdi-weather-snowy-rainy",
        "mdi-weather-snowy-rainy",
        "mdi-weather-snowy-rainy",
        "mdi-weather-snowy",
        "mdi-weather-snowy",
        "mdi-weather-snowy",
        "mdi-weather-snowy",
        "mdi-weather-snowy",
        "mdi-weather-sunny",
        "mdi-weather-sunny",
      ],
    };
  },

  computed: {
    icons() {
      if (this.searchTerm != "" && this.searchTerm != null)
        return this.avaliableIcons.filter((e) => e.includes(this.searchTerm));
      return this.avaliableIcons;
    },
  },

  methods: {
    setValue(v) {
      this.iconValue = v;
      this.$emit("update", v);
    },

    toggleDisplay() {
      this.display = !this.display;
    },
  },

  watch: {
    icon(v) {
      this.iconValue = v;
    },

    open(v) {
      this.display = v;
    },
  },
};
</script>

<style>
.icon-selector {
  background-color: #252525;
  color: white;
}
</style>