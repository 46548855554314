export const event = {
  namespaced: true,
  state: {
    events: [],
  },

  actions: {
    addEvent({ commit }, payload) {
      commit("SET_EVENT", payload);
    },
  },

  mutations: {
    SET_EVENT(state, payload) {
      state.events.unshift(payload);
      if (state.events.length > 50) state.events.pop();
    },
  },

  getters: {
    lastEvent(state) {
      if (state.events.length <= 0) return {};

      return state.events[0];
    },
  },
};
