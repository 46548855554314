import Repository from "../Repository";
import { apiConfig, getAuthHeader } from "../../_helpers/apiHelper";

const resource = "/license";

export default {
  getLicenses(user) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(resource, config);
  },

  getTerms(user) {
    var config = {};
    if (user !== undefined || user !== null) {
      config["headers"] = getAuthHeader(user);
    }

    return Repository.get(`${resource}/terms`, config);
  },

  uploadLicense(user, license) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.post(`${resource}/upload`, license, config);
  },

  activate(user, licenseId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.put(`${resource}/activate/${licenseId}`, null, config);
  },

  removeLicense(user, licenseId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.delete(`${resource}/remove/${licenseId}`, config);
  },

  getCompanyLimitations(user) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(`${resource}/companies`, config);
  },

  createOrUpdateCompanyLimitation(
    user,
    companyId,
    maxGateways = 0,
    maxNodes = 0
  ) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.post(
      `${resource}/company/${companyId}/${maxGateways}/${maxNodes}`,
      null,
      config
    );
  },

  getLicenseServerMeta(user) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(`${resource}/meta`, config);
  },

  canCreate(user, companyId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(`${resource}/can-create/${companyId}`, config);
  },

  nodesLeftForCompany(user, companyId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(`${resource}/nodes-left/${companyId}`, config);
  },

  getUserAcceptedTos(email) {
    return Repository.get(`${resource}/tos/${email}`, apiConfig());
  },

  acceptTos(email) {
    return Repository.put(`${resource}/tos/${email}`, null, apiConfig());
  },
};
