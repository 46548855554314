import Repository from "../Repository";
import { getAuthHeader } from "../../_helpers/apiHelper";

const resource = "/alarms";
const companyResource = "/companies";

export default {
  get(user, companyId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(`${companyResource}/${companyId}/alarm`, config);
  },

  getById(user, alarmId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(`${resource}/${alarmId}`, config);
  },

  create(user, companyId, alarmPayload) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.post(
      `${companyResource}/${companyId}/alarm`,
      alarmPayload,
      config
    );
  },

  update(user, companyId, alarmId, alarmPayload) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.put(
      `${companyResource}/${companyId}/alarm/${alarmId}`,
      alarmPayload,
      config
    );
  },

  getActiveAlarms(user, companyId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(
      `${companyResource}/${companyId}/alarm/active`,
      config
    );
  },

  getAlarmHistory(user, companyId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(
      `${companyResource}/${companyId}/alarm/history`,
      config
    );
  },

  setActiveAlarmStatus(user, activeAlarmId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.post(
      `${resource}/active/${activeAlarmId}/status`,
      null,
      config
    );
  },

  delete(user, alarmId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.delete(`${resource}/${alarmId}`, config);
  },

  duplicate(user, alarmId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.post(`${resource}/copy/${alarmId}`, null, config);
  },

  testSettings(user, payload) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.post(`${resource}/setting/test`, payload, config);
  },
};
