import Repository from "../Repository";
import { getAuthHeader } from "../../_helpers/apiHelper";

const resource = "/downlink";

export default {
  getForTag(user, deveui) {
    var config = {
      headers: getAuthHeader(user)
    };

    return Repository.get(`${resource}/tag/${deveui}`, config);
  },

  removeDownlink(user, downlinkQueueId) {
    var config = {
      headers: getAuthHeader(user)
    };

    return Repository.delete(`${resource}/${downlinkQueueId}`, config);
  },

  createDownlink(user, deveui, payload) {
    var config = {
      headers: getAuthHeader(user)
    };

    return Repository.post(`${resource}/${deveui}`, payload, config);
  }
};
